import React from 'react'
import { MapPin, Smartphone, Mail } from 'react-feather'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import './ContactPage.css'

// Export Template for use in CMS preview
export const ContactPageTemplate = ({
  body,
  title,
  subtitle,
  featuredImage,
  address,
  phone,
  email,
  map_url
}) => (
  <main className="Contact">
    <PageHeader
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />
    <section className="section Contact--Section1">
      <div className="container Contact--Section1--Container">
          <div className="Contact--Details">
            {address && (
              <span className="Contact--Details--Item">
                <MapPin /> <Content source={address}/>
              </span>
            )}
            {phone && (
              <span className="Contact--Details--Item">
                <Smartphone /> {phone}
              </span>
            )}
            {email && (
              <span className="Contact--Details--Item">
                <Mail /> <Content source={email}/>
              </span>
            )}
			{map_url && (
				<div className="taCenter">
					<a href={map_url} className="Button Button--Contact">
							地図・アクセス
					</a>
				</div>
			)}
          </div>
      </div>
    </section>

  </main>
)

const ContactPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ContactPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
		subtitle
        template
        featuredImage
        address
        phone
        email
		map_url
      }
    }
  }
`
